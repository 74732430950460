import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/fonts.ts\",\"import\":\"Urbanist\",\"arguments\":[{\"variable\":\"--sans-font\",\"subsets\":[\"latin\"],\"style\":[\"normal\"],\"weight\":[\"600\",\"900\"]}],\"variableName\":\"urbanist\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/fonts.ts\",\"import\":\"Fira_Code\",\"arguments\":[{\"variable\":\"--mono-font\",\"subsets\":[\"latin\"],\"style\":[\"normal\"],\"weight\":[\"400\",\"600\"]}],\"variableName\":\"firaCode\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/common/loader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/common/typewriter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/terminal/terminal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/providers/terminal.provider.tsx");
